<template>
  <div id="pdf">
    <div id="demo"></div>
  </div>
</template>
<script>
import Pdfh5 from "pdfh5";
import { download } from "../utils/api";
export default {
  name: "pdf",
  data() {
    return {
      pdfh5: null,
    };
  },

  mounted() {
    
    if(this.$route.query.id){
      let url = `${download}${this.$route.query.productId}/${this.$route.query.id}/2`
      this.pdf(url)
    }else if(this.$route.query.type == 'common'){
      document.title = '公共责任保险条款';
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/officialAccount/common.pdf');
    }else if(this.$route.query.type == 'employer'){
      document.title = '雇主责任险（2016版）条款';
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/officialAccount/employer.pdf');
    }else if(this.$route.query.type == 'property'){
      document.title = '财产一切险条款';
      this.pdf('https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/officialAccount/property.pdf');
    }
  },
  methods: {
    //下载查看
    pdf(url) {
      let pdfUrl = url;
      //实例化
      this.pdfh5 = new Pdfh5("#demo", {
        pdfurl: pdfUrl,
        lazy: true,
      }).on("complete", function (status, msg, time) {
        //监听完成事件
        console.log(
          "状态：" +
            status +
            "，信息：" +
            msg +
            "，耗时：" +
            time +
            "毫秒，总页数：" +
            this.totalNum
        );
      });
    },
  },
};
</script>
 
<style lang="scss" scoped>
#pdf {
  width: 100%;
  /deep/ .pinch-zoom-container {
    height: 100vh !important;
  }
  /deep/ .pageContainer {
    width: 100% !important;
  }
  /deep/ img {
    width: 100% !important;
  }
}
</style>